import React from "react"
import Layout from "../templates/layout"
import SEO from "../components/seo/seo"
import Hero from "../components/hero/hero"
import Card from "../components/card/card"
import LinkCard from "../components/linkCard/linkCard"
import HaveQuestions from "../components/haveQuestions/haveQuestions"
import SarclisaLogo from "../images/logos/sarclisa_patient.svg";
import AffordIcon from "../images/icons/icon-financial-sarclisa.png"
import EnrollIcon from "../images/icons/icon-enroll-sarclisa-hero.png";
import FAQIcon from "../images/icons/icon-faq-sarclisa.png"
import QuestionsIcon from "../images/icons/icon-questions-sarclisa.svg"
import BackToTop from "../components/backToTop/BackToTop";
import { Link } from "gatsby"

const pageContent = () => (

    <Layout pageid="page-home">
      <SEO
      title="CareASSIST for patients prescribed SARCLISA® (isatuximab-irfc) | Patient Site"
      keywords="CareASSIST, Sanofi, SARCLISA® (isatuximab-irfc), Patient Site"
      description="Info for patients on CareASSIST by Sanofi, including financial assistance, enrollment, and resource support for SARCLISA patients. Contact a Care Manager. See Prescribing Info, including Patient Info"
      />
     
     <Hero
      brandLogo={SarclisaLogo}
      brandAlt="Sarclisa Logo"
      headline={<>Helping eligible patients access SARCLISA</>}
      copy="CareASSIST guides you through your SARCLISA treatment journey, with a dedicated Care Manager to provide support every step of the way"
      heroImgClass="hero-img"
      hasButton={false}
      btnLink="/sarclisa/enrollment/"
      btnText=""
      btnClass="sarclisa"
      hasLogo={true}
      subheadline="Talk to your doctor about enrolling today"
      linkId="patient-sarclisa-enroll-today"
      />
      
      <section className="content-section" id="overview">
        <div className="flex-section between home-card-container">
          <LinkCard
          img={AffordIcon}
          alt="Financial Assistance Icon"
          cardTitle={<>Financial<br/>assistance</>}
          cardText="Discover how much you may be able to save"
          cardLink=""
          cardClass="product-home sarclisa-home"
          linkID="patient-sarclisa-financial-assistance-link"
          linkImgID="financial-assistance-link-image"
          linkTitleID="financial-assistance-link-title"
          linkTxtID="financial-assistance-link-text"
          btnLink="/sarclisa/financial-assistance/"
          btnClass="sarclisa"
          />
          <LinkCard
          img={EnrollIcon}
          alt="Resource support icon"
          cardTitle={<>Resource<br/>support</>}
          cardText="Get connected to independent support organizations and resources "
          cardLink=""
          cardClass="product-home sarclisa-home"
          linkID="patient-sarclisa-resource-support-link"
          linkImgID="enrollment-link-image"
          linkTitleID="enrollment-link-title"
          linkTxtID="enrollment-link-text"
          btnLink="/sarclisa/resource-support/"
          btnClass="sarclisa"
          />
          <LinkCard
          img={FAQIcon}
          alt="Frequently Asked Questions Icon"
          cardTitle="Frequently asked questions"
          cardText="Your questions, answered"
          cardLink=""
          cardClass="product-home sarclisa-home"
          linkID="patient-sarclisa-faq-link"
          linkImgID="faq-link-image"
          linkTitleID="faq-link-title"
          linkTxtID="faq-link-text"
          btnLink="/sarclisa/faqs"
          btnClass="sarclisa"
          />
        </div>
        {/* <div className="title-bar">
          <h2>Useful resources</h2>
          <Link to="/sarclisa/resource-support/" className="view-all" title="View alll resources link">View all resources</Link>
        </div>
        <div className="flex-section between">
          <Card 
          cardTitle="CareASSIST patient overview flashcard"
          cardText="A brief brochure explaining our program"
          isExternalLink={true}
          linkUrl="../pdfs/careassist-patient-support-program-overview-resource-patient.pdf"
          linkClass="download"
          linkTitle="Download the Patient overview brochure"
          linkText="Download"
          />
          <Card 
          cardTitle="Enrollment form"
          cardText="Complete this form with your doctor to enroll in CareASSIST"
          isExternalLink={true}
          linkUrl="../pdfs/careassist-program-enrollment-form.pdf"
          linkClass="download"
          linkTitle="Download the Enrollment Form"
          linkText="Download"
          />
          <Card 
          cardTitle="Spanish enrollment form"
          cardText="Spanish language enrollment form"
          isExternalLink={true}
          linkUrl="../pdfs/careassist-program-enrollment-form-spanish-version.pdf"
          linkClass="download"
          linkTitle="View the Letter of medical necessity site"
          linkText="Download"
          />
        </div> */}
        <p className="pi-paragraph">Please see full <a id="patient-sarclisa-pi" href="https://products.sanofi.us/Sarclisa/sarclisa.pdf" target="_blank" rel="noreferrer">Prescribing Information</a>, including <a id="patient-sarclisa-patient-info" href="https://products.sanofi.us/Sarclisa/sarclisa_patient_information.pdf" target="_blank">Patient Information</a>.</p>
        <HaveQuestions 
        icon={QuestionsIcon}
        />
      </section>
      <div className="back-to-top-container">
        <BackToTop/>
      </div>

    </Layout>

)

export default pageContent